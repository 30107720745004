import type { ReactNode } from "react";
import React from "react";
import { twMerge } from "tailwind-merge";

interface Props {
  Component?: JSX.ElementType;
  className?: string;
  href?: string;
  unpadded?: boolean;
  flat?: boolean;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  children: ReactNode;
}

const Card = React.forwardRef<HTMLDivElement, Props>(
  ({ children, className, Component = "div", unpadded = false, flat = false, ...rest }, ref) => {
    const rootClassName = twMerge(
      "bg-white rounded-lg",
      flat ? "border border-gray-200" : "shadow-card",
      unpadded ? "p-0" : "p-4 md:py-6 md:px-8",
      (Component === "button" || Component === "a") &&
        "hover:border-gray-300 hover:shadow-card-hover focus:outline-none focus:ring-1 focus:ring-indigo-600 text-left",
      className
    );

    return (
      <Component ref={ref} {...rest} className={rootClassName}>
        {children}
      </Component>
    );
  }
);

Card.displayName = "Card";

export default Card;
